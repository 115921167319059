<template>
  <transition name="slide-fade">
    <Card v-show="showTabs" class="p-col">
      <template #title>
        <div class="p-d-flex p-jc-between">
          <div>
            <h3 class="p-m-0"><strong>{{ originalUserGroupObject.name }}</strong></h3>
          </div>
          <div>
            <Button v-if="canBeEdited" v-tooltip.bottom="_t('Cancel')"
                    class="p-button-rounded p-button-danger p-button-text" icon="pi pi-times"
                    @click="canBeEdited = false"/>
            <Button
                v-tooltip.bottom="canBeEdited ? _t('Save') :_t('Edit')"
                :class="canBeEdited ? 'p-button-rounded p-button-text p-button-success' : 'p-button-rounded p-button-text p-button-secondary' "
                :disabled="originalUserGroupObject.name === 'Admin Group'"
                :icon="canBeEdited ? 'pi pi-check' : 'pi pi-pencil'"
                @click="processEditing"
            />
            <Button
                v-if="!canBeEdited && !readOnlyGroups.includes(originalUserGroupObject.id)"
                v-tooltip.bottom="_t('Delete')"
                :disabled="originalUserGroupObject.name === 'Admin Group'" class="p-button-rounded p-button-danger p-button-text"
                icon="pi pi-trash"
                @click="deleteUserGroupDialog = true"
            />
          </div>
        </div>

      </template>
      <template #content>
        <div class="p-grid">
          <div class="p-col-12">
            <div class="card">
              <TabView>
                <TabPanel :header="_t('menu.general')">
                  <div class="p-grid">
                    <div class="p-col-4 stripedBg">
                      <p>{{ _t('label_object_name') }}<sup>*</sup></p>
                    </div>
                    <div class="p-col-8 stripedBg">
                      <div
                          v-if="canBeEdited && !readOnlyGroups.includes(originalUserGroupObject.id) && originalUserGroupObject.name !== 'Admin Group'"
                          class="p-inputgroup">
                        <span class="p-inputgroup-addon">
                          <i class="pi pi-key"></i>
                        </span>
                        <InputText v-model="originalUserGroupObject.name"
                                   class="p-m-0"
                                   placeholder="Role name" style="width:100%;"
                        />
                      </div>
                      <p v-else><strong>{{ originalUserGroupObject.name }}</strong></p>
                    </div>
                    <div class="p-col-4">
                      <p>{{ _t('label_description') }}</p>
                    </div>
                    <div class="p-col-8">
                      <div v-if="canBeEdited" class="p-inputgroup">
                        <span class="p-inputgroup-addon">
                          <i class="pi pi-key"></i>
                        </span>
                        <InputText v-model="originalUserGroupObject.description"
                                   class="p-m-0"
                                   placeholder="Role description" style="width:100%;"
                        />
                      </div>
                      <p v-else><strong>{{ originalUserGroupObject.description }}</strong></p>
                    </div>
                    <div class="p-col-4 stripedBg" style="vertical-align: middle;">
                      <p>{{ _t('label_organization') }}</p>
                    </div>
                    <div class="p-col-8 stripedBg">
                      <OrganizationLink
                          v-model="originalUserGroupObject"
                          :editMode="canBeEdited && !readOnlyGroups.includes(originalUserGroupObject.id) && originalUserGroupObject.name !== 'Admin Group'"/>
                    </div>
                    <div class="p-col-4">
                      <p>{{ _t('Force2FA') }}</p>
                    </div>
                    <div class="p-col-8">
                      <Checkbox
                          v-model="force2FA"
                          :binary="true"
                          :disabled="true"
                      />

                    </div>

                  </div>
                </TabPanel>
                <TabPanel :header="_t('permissions')">
                  <div class="p-grid">
                    <div v-for="globalPermission of globalPermissions" :key="globalPermission"
                         class="p-field-checkbox p-col-12 p-md-4">
                      <Checkbox :id="'gl_p_'+globalPermission"
                                v-model="originalUserGroupObject.generalPermissions"
                                :disabled="!canBeEdited || readOnlyGroups.includes(originalUserGroupObject.id)"
                                :value="globalPermission"
                                name="globalPermission"/>
                      <label :for="'gl_p_'+globalPermission">{{ formatTitle(globalPermission) }}</label>
                    </div>
                  </div>
                </TabPanel>
                <TabPanel
                    :header="_t('admin_permissions')"
                >
                  <div class="p-grid">
                    <div v-for="adminPermission of adminPermissions" :key="adminPermission"
                         class="p-field-checkbox p-col-12 p-md-4">
                      <Checkbox :id="'adm_p_'+adminPermission"
                                v-model="originalUserGroupObject.adminPermissions"
                                :disabled="!canBeEdited || readOnlyGroups.includes(originalUserGroupObject.id)"
                                :value="adminPermission"
                                name="adminPermission"/>
                      <label :for="'adm_p_'+adminPermission">{{ formatTitle(adminPermission) }}</label>
                    </div>
                  </div>
                </TabPanel>
                <TabPanel
                    :header="_t('label_access_rights')"
                >
                  <TreeTable
                      v-model:expanded-keys="expandedTreeTableKeys"
                      v-model:selectionKeys="selectedTreeTableValue"
                      :value="organizations"
                      autoLayout
                      class="p-treetable-sm"
                      showGridlines
                      @node-expand="appendNode($event)"
                  >
                    <Column :expander="true" field="name" header="Name">
                    </Column>

                    <Column>
                      <template #header>
                        <div
                            style="display: flex; flex-direction: column; align-items: center; justify-content: center;">
                          <p style="font-size: 14px;"><strong>{{ _t('USER') }}</strong></p>
                          <div style="display: flex; flex-direction: row; justify-content: center;">
                            <Button v-for="value in userRights" :key="value" v-tooltip.top="formatTitle(value)"
                                    :icon="icons[value.toLowerCase()]"
                                    class="p-button-link" style="color: black; width: 50px; height: 35px;">
                            </Button>
                          </div>
                        </div>
                      </template>
                      <template #body="slotProps">
                        <div style="display: flex; justify-content: center;">
                          <Button
                              v-for="item in userRights" :key="item"
                              v-tooltip.bottom="formatTitle(slotProps.node.data.rights[item])"
                              :disabled="!canBeEdited || readOnlyGroups.includes(originalUserGroupObject.id)"
                              :icon="actions[slotProps.node.data.rights[item] ? slotProps.node.data.rights[item].toLowerCase() : 'null'].icon"
                              :style="`width: 50px; height: 30px; color: ${actions[slotProps.node.data.rights[item] ? slotProps.node.data.rights[item].toLowerCase() : 'null' ].color};`"
                              class="p-button-text"
                              @click="editValue(slotProps.node, slotProps.node.data.rights[item], item)">
                          </Button>
                        </div>
                      </template>
                    </Column>

                    <Column>
                      <template #header>
                        <div
                            style="display: flex; flex-direction: column; align-items: center; justify-content: center;">
                          <p style="font-size: 14px;"><strong>{{ _t('PARTICIPANT') }}</strong></p>
                          <div style="display: flex; flex-direction: row; justify-content: center;">
                            <Button v-for="value in participantRights" :key="value" v-tooltip.top="formatTitle(value)"
                                    :icon="icons[value.toLowerCase()]"
                                    class="p-button-link" style="color: black; width: 50px; height: 35px;">
                            </Button>
                          </div>
                        </div>
                      </template>

                      <template #body="slotProps">
                        <div style="display: flex; justify-content: center;">
                          <Button
                              v-for="item in participantRights" :key="item"
                              v-tooltip.bottom="formatTitle(slotProps.node.data.rights[item])"
                              :disabled="!canBeEdited || readOnlyGroups.includes(originalUserGroupObject.id)"
                              :icon="actions[slotProps.node.data.rights[item] ? slotProps.node.data.rights[item].toLowerCase() : 'null'].icon"
                              :style="`width: 50px; height: 30px; color: ${actions[slotProps.node.data.rights[item] ? slotProps.node.data.rights[item].toLowerCase() : 'null' ].color};`"
                              class="p-button-text"
                              @click="editValue(slotProps.node, slotProps.node.data.rights[item], item)">
                          </Button>
                        </div>
                      </template>
                    </Column>

                    <Column>
                      <template #header>
                        <div
                            style="display: flex; flex-direction: column; align-items: center; justify-content: center;">
                          <p style="font-size: 14px;"><strong>{{ _t('ALARM_POINT') }}</strong></p>
                          <div style="display: flex; flex-direction: row; justify-content: center;">
                            <Button v-for="value in alarmRights" :key="value" v-tooltip.top="formatTitle(value)"
                                    :icon="icons[value.toLowerCase()]"
                                    class="p-button-link" style="color: black; width: 50px; height: 35px;">
                            </Button>
                          </div>
                        </div>
                      </template>

                      <template #body="slotProps">
                        <div style="display: flex; justify-content: center;">
                          <Button
                              v-for="item in alarmRights" :key="item"
                              v-tooltip.bottom="formatTitle(slotProps.node.data.rights[item])"
                              :disabled="!canBeEdited || readOnlyGroups.includes(originalUserGroupObject.id)"
                              :icon="actions[slotProps.node.data.rights[item] ? slotProps.node.data.rights[item].toLowerCase() : 'null'].icon"
                              :style="`width: 50px; height: 30px; color: ${actions[slotProps.node.data.rights[item] ? slotProps.node.data.rights[item].toLowerCase() : 'null' ].color};`"
                              class="p-button-text"
                              @click="editValue(slotProps.node, slotProps.node.data.rights[item], item)">
                          </Button>
                        </div>
                      </template>
                    </Column>

                    <Column>
                      <template #header>
                        <div
                            style="display: flex; flex-direction: column; align-items: center; justify-content: center;">
                          <p style="font-size: 14px;"><strong>{{ _t('OTHER') }}</strong></p>
                          <div style="display: flex; flex-direction: row; justify-content: center;">
                            <Button v-for="value in otherRights" :key="value" v-tooltip.top="formatTitle(value)"
                                    :icon="icons[value.toLowerCase()]"
                                    class="p-button-link" style="color: black; width: 50px; height: 35px;">
                            </Button>
                          </div>
                        </div>
                      </template>

                      <template #body="slotProps">
                        <div style="display: flex; justify-content: center;">
                          <Button
                              v-for="item in otherRights" :key="item"
                              v-tooltip.bottom="formatTitle(slotProps.node.data.rights[item])"
                              :disabled="!canBeEdited || readOnlyGroups.includes(originalUserGroupObject.id)"
                              :icon="actions[slotProps.node.data.rights[item] ? slotProps.node.data.rights[item].toLowerCase() : 'null'].icon"
                              :style="`width: 50px; height: 30px; color: ${actions[slotProps.node.data.rights[item] ? slotProps.node.data.rights[item].toLowerCase() : 'null' ].color};`"
                              class="p-button-text"
                              @click="editValue(slotProps.node, slotProps.node.data.rights[item], item)">
                          </Button>
                        </div>
                      </template>
                    </Column>

                  </TreeTable>
                </TabPanel>
                <TabPanel
                    v-if="userGroup"
                    :header="_t('label_users')"
                >
                  <div class="p-grid">
                    <div v-for="user in userGroup.users" :key="user.id" class="p-col-4">
                      <i class="pi pi-user p-mb-2" style="font-size: 12px"/>
                      <Button class="p-button-link p-button-success" style="font-size: 12px" @click="toUser(user.id)">
                        {{ user.surname }}, {{ user.name }}
                      </Button>
                    </div>
                  </div>
                </TabPanel>
              </TabView>
            </div>
          </div>
        </div>
      </template>
    </Card>
  </transition>

  <Dialog v-model:visible="deleteUserGroupDialog">
    <template #header>
      <h3>Delete role</h3>
    </template>
    <p>Do you want to delete role <strong>{{ originalUserGroupObject.name }}</strong>?</p>
    <template #footer>
      <Button :label="_t('Cancel')" class="p-button-outlined p-button-secondary"
              @click="deleteUserGroupDialog = false"/>
      <Button :label="_t('Delete')" autofocus class="p-button-success" @click="deleteUserGroup()"/>
    </template>
  </Dialog>

</template>

<script>
import router from '@/router';
import NodeService from '@/service/NodeService'
import {
  deleteUserGroup,
  findUserGroup,
  getAccessRights,
  getAdminPermissions,
  getPermissions,
  getUGTree,
  updateUserGroup
} from '@/api/userGroup'
import {getTree} from "@/api/tree";

export default {
  name: 'userGroupItem',
  props: {
    parentData: {
      type: String,
      default() {
        return null
      }
    }
  },
  data() {
    return {
      canBeEdited: false,
      organizations: [],
      selectedTreeTableValue: null,
      expandedTreeTableKeys: {},
      globalPermissions: [],
      adminPermissions: [],
      accessRights: [],
      showTabs: false,
      userGroup: {
        name: '',
        users: []
      },
      icons: {
        user: 'pi pi-fw pi-user',
        user_group: 'pi pi-fw pi-users',
        participant: 'pi pi-fw pi-user-minus',
        participant_group: 'pi pi-fw pi-users',
        participant_rotational_cal: 'pi pi-fw pi-tablet',
        participant_individual_cal: 'pi pi-fw pi-mobile',
        alarm_point: 'pi pi-fw pi-bell',
        alarm_point_rotational_cal: 'pi pi-fw pi-calendar-times',
        alarm_point_individual_cal: 'pi pi-fw pi-calendar-plus',
        alarm_plan: 'pi pi-fw pi-map',
        organization: 'pi pi-fw pi-globe',
        holiday: 'pi pi-fw pi-calendar-minus',
        device: 'pi pi-fw pi-desktop'
      },
      actions: {
        read: {
          icon: 'pi pi-fw pi-eye',
          color: 'blue'
        },
        write: {
          icon: 'pi pi-fw pi-pencil',
          color: 'green'
        },
        none: {
          icon: 'pi pi-fw pi-times',
          color: 'red'
        },
        null: {
          icon: '',
          color: 'grey'
        }
      },
      originalUserGroupObject: {
        name: ''
      },
      originalOrganisationsObject: [],
      deleteUserGroupDialog: false,
      readOnlyGroups: [31],
      userRights: [],
      participantRights: [],
      alarmRights: [],
      otherRights: [],
      force2FA: true
    }
  },
  watch: {
    parentData(value) {
      if (value !== null) {
        this.showTabs = true
        this.findUserGroup()
        this.getAdminPermissions()
        this.getAccessRights()
        this.getPermissions()
      } else {
        this.showTabs = false
      }
      this.canBeEdited = false
    },
  },
  nodeService: null,
  created() {
    this.nodeService = new NodeService();
  },
  methods: {
    findUserGroup() {
      this.expandedTreeTableKeys = {};
      findUserGroup(this.parentData).then((response) => {
        this.originalUserGroupObject = this.userGroup = response.data;
        this.userGroup.accessRightsMapped = this.userGroup.accessRights.map(item => {
          const container = {data: {}};
          container.data.name = item.treeNode.name;
          this.companyId = item.treeNode.companyId
          for (const right of item.rights) {
            container.data[right.category] = right.value
          }
          container.leaf = !item.hasChildren
          container.key = item.treeNode.id;
          return container;
        })
        this.getOrganizations()
      });
    },
    getAccessRights() {
      this.accessRights = []
      this.userRights = []
      this.participantRights = []
      this.alarmRights = []
      this.otherRights = []
      getAccessRights().then((response) => {
        const groups = response.data
        for (const group in groups) {
          this.accessRights = this.accessRights.concat(groups[group])
          if (group === 'ALARM') {
            this.alarmRights = groups['ALARM']
          } else if (group === 'OTHER') {
            this.otherRights = groups['OTHER']
          } else if (group === 'PARTICIPANT_CATEGORY') {
            this.participantRights = groups['PARTICIPANT_CATEGORY']
          } else {
            this.userRights = groups['USER_CATEGORY']
          }
        }
      });
    },
    getPermissions() {
      getPermissions().then((response) => {
        this.globalPermissions = response.data
      });
    },
    getAdminPermissions() {
      getAdminPermissions().then((response) => {
        this.adminPermissions = response.data
      });
    },
    getOrganizations() {
      getUGTree(this.userGroup.orgId).then((response) => {
        this.originalOrganisationsObject.push(response.data)
        response.data = [response.data]
        this.organizations = response.data.map(item => {
              return this.mapAccessRights(item);
            }
        )
      })
    },
    appendNode(item) {
      getTree(item.key).then((response) => {
        for (const org of response.data) {
          this.originalOrganisationsObject.push(org)
        }
        item.children = response.data.map(item => {
          return this.mapAccessRights(item);
        })
      })
    },
    editValue(node, key, item) {
      key = key ? key.toLowerCase() : key = 'read'

      if (key === 'read') {
        key = 'write'
      } else if (key === 'write') {
        key = 'none'
      } else if (key === 'none') {
        key = 'null'
      } else {
        key = 'read'
      }

      node.data.rights[item] = key.toUpperCase()
      const mappedRights = [];
      for (const [key, value] of Object.entries(node.data.rights)) {
        if (key !== 'name') {
          mappedRights.push({
            category: key,
            value: value === 'null' ? null : value
          })
        }
      }

      const originalAccessRightsIndex = this.originalUserGroupObject.accessRightsMapped.findIndex(item => item.key === node.key)
      if (originalAccessRightsIndex === -1) {
        const treeNode = this.originalOrganisationsObject.find(item => item.id === node.key)
        this.originalUserGroupObject.accessRights.push({
          treeNode: treeNode,
          rights: mappedRights
        })
      } else {
        this.originalUserGroupObject.accessRights[originalAccessRightsIndex].rights = mappedRights
      }
    },
    mapAccessRights(item) {
      const container = {
        data: {
          rights: []
        }
      };
      container.data.name = item.name;

      for (const key of Object.values(this.accessRights)) {
        container.data.rights[key] = null
      }

      const accessRights = this.userGroup.accessRightsMapped
      for (const accessRight of accessRights) {
        if (accessRight.key === item.id) {
          container.data.rights = accessRight.data
        }
      }
      container.leaf = !item.hasChildren
      container.key = item.id;
      return container;
    },
    processEditing() {
      if (this.canBeEdited === true) {
        this.saveUserGroup()
      }
      this.canBeEdited = !this.canBeEdited
    },
    saveUserGroup() {
      updateUserGroup(this.originalUserGroupObject).then((response) => {
        this.originalUserGroupObject.version = response.data.version
      }).catch((error) => {
        console.log(error)
        let data = error.response.data ? error.response.data instanceof Object ? error.response.data : JSON.parse(error.response.data) : {}
        this.$root.showMessage((data.reason ? ': ' + data.reason : ''), 'error')
      })
      this.expandedTreeTableKeys = {};
    },
    formatTitle(title) {
      const labelsWithPrefix = ['REPORTS', 'MANUAL_CALL', 'ALARM_EVENT_RULE', 'INSTRUCTION_FILES'];
      const labelsFromMenu = ['IMPORT'];
      if (!title) return ''
      if (labelsWithPrefix.indexOf(title) !== -1) {
        title = 'label_' + title.toLowerCase()
      }
      if (labelsFromMenu.indexOf(title) !== -1) {
        title = 'menu.' + title.toLowerCase()
      }
      if (title === 'NONE') {
        title = '_NONE'
      } else if (title === 'NULL') {
        title = 'label_nil'
      }
      return this._t(title)
    },
    deleteUserGroup() {
      this.deleteUserGroupDialog = false
      deleteUserGroup(this.originalUserGroupObject.id).then(() => {
        this.showTabs = false
        this.$parent.getUserGroups();
      }).catch((error) => {
        console.log(error)
        let data = error.response.data ? error.response.data instanceof Object ? error.response.data : JSON.parse(error.response.data) : {}
        this.$root.showMessage((data.reason ? ': ' + data.reason : 'Error deleting role'), 'error')
      })
    },
    toUser(id) {
      router.push(`/users/${id}`)
    },
  }
}
</script>

<style lang="scss" scoped>

::v-deep(.p-treetable) {
  .p-treetable-tbody > tr > td {
    padding: 5px;

  }

  .p-treetable-thead > tr > th {
    padding: 5px;

  }

}

</style>
